<template>
  <v-row class="mt-5">
    <v-col cols="12" md="8">
      <v-card outlined>
        <v-col cols="12" md="8" class="py-0">
          <v-checkbox v-model="form.has_corporate_rates">
            <template slot="label">
              <span class="f-17 font-regular color-primary"
                >Corporate Rates</span
              >
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="12" class="py-0" v-if="form.has_corporate_rates">
          <v-row
            class="my-0"
            v-for="(rate, index) in form.corporate_rates"
            :key="`rate_ ${+index}`"
          >
            <v-col cols="12" md="4" class="py-0">
              <v-menu
                v-model="form.corporate_rates[index].start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.corporate_rates[index].start_date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  >
                    <template slot="label">
                      <span class="f-15 font-regular">From Date</span>
                    </template>

                    <template slot="append">
                      <v-btn
                        icon
                        @click="
                          form.corporate_rates[index].start_date_menu = true
                        "
                      >
                        <v-icon small>mli-calendar-empty</v-icon>
                      </v-btn>
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  :min="currentDate"
                  v-model="form.corporate_rates[index].start_date"
                  @input="form.corporate_rates[index].start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-menu
                v-model="form.corporate_rates[index].end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.corporate_rates[index].end_date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  >
                    <template slot="label">
                      <span class="f-15 font-regular">To Date</span>
                    </template>
                    <template slot="append">
                      <v-btn
                        icon
                        @click="
                          form.corporate_rates[index].end_date_menu = true
                        "
                      >
                        <v-icon small>mli-calendar-empty</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  :min="form.corporate_rates[index].start_date"
                  v-model="form.corporate_rates[index].end_date"
                  @input="form.corporate_rates[index].end_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="10" md="3" class="d-flex" style="padding: 10px 0">
              <v-text-field
                v-model="form.corporate_rates[index].starting_price"
                :rules="[getRules.required, getRules.germanNumber]"
                dense
                clearable
                append-icon="mli-euro"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" md="1" class="justify-end d-flex align-start">
              <v-icon @click="removeCorporateRate(index)" color="primary">
                mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" md="8" class="py-0 pb-3">
              <div class="pointer text--secondary" @click="addCorporateRate">
                <i class="mdi mdi-plus"></i> Add seasonal corporate rates
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "components",
  props: ["form"],
  computed: {
    ...mapGetters(["getRules"]),
  },
  data: () => ({
    currentDate: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    "form.corporate_rates": {
      handler(rates) {
        rates.forEach((rate, index) => {
          if (rate.starting_price) {
            this.form.corporate_rates[index].starting_price = parseFloat(
              rate.starting_price.toString().replace(",", ".")
            );
          }

          if (rate.start_date > rate.end_date) {
            this.form.corporate_rates[index].end_date = rate.start_date;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    addCorporateRate() {
      this.form.corporate_rates.push({
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        starting_price: "",
        start_date_menu: false,
        end_date_menu: false,
      });
    },
    removeCorporateRate(index) {
      this.form.corporate_rates.splice(index, 1);
    },
  },
};
</script>
<style >
.mli-euro {
  font-size: 15px !important;
}
</style>
